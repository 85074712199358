import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";
const firebaseConfig = {
	apiKey: "AIzaSyAEKboLkfRmrU16J4hA390k--J5SFZUpS4",
	authDomain: "aston-martin-c0f1f.firebaseapp.com",
	projectId: "aston-martin-c0f1f",
	storageBucket: "aston-martin-c0f1f.appspot.com",
	messagingSenderId: "413567578929",
	appId: "1:413567578929:web:2fe187e7ba6c6cafb47610",
	measurementId: "G-MSDZ42DT30",
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
export const firebaseAddMail = (values) => {
	return addDoc(collection(db, "mail"), { values });
};
