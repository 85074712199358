import React, { useState, useRef, useEffect } from "react";
import R_NAV from "../../assets/icons/right_nav_arrow.png";
import L_NAV from "../../assets/icons/left_nav_arrow.png";

const importAll = (r) => {
	let collection = {};

	r.keys().map((i) => {
		collection[i.replace("./", "")] = r(i);
	});
	return collection;
};
const images = importAll(
	require.context("../../assets/gallery", false, /\.png|\.jpg/)
);
const Carousel = ({ resize }) => {
	const carouselContainerRef = useRef();
	const [currentImgIdx, setcurrentImgIdx] = useState(8);
	//const [xDelta, setXDelta] = useState(-(Object.keys(images).length * 325) / 2);
	const [imgWidth, setImgWidth] = useState(null);
	const [xDelta, setXDelta] = useState(null);
	const imageWidth = window.innerWidth / 3 / 1.1;
	const imagesListLength = Object.keys(images).length;
	let sameTick = false;

	const throttler = (cb) => {
		if (!sameTick) {
			cb();
		}
		sameTick = true;
		setTimeout(() => {
			sameTick = false;
		}, 1000);
	};
	console.log(xDelta, imgWidth);
	const navigateRight = () => {
		setcurrentImgIdx((i) => (i === 0 ? 0 : i - 1));
		return xDelta < imgWidth ? setXDelta((x) => x + imgWidth) : null;
	};
	const navigateLeft = () => {
		setcurrentImgIdx((i) =>
			i === imagesListLength - 1 ? imagesListLength - 1 : i + 1
		);
		return xDelta > -(imagesListLength - 2.5) * imgWidth
			? setXDelta((x) => x - imgWidth)
			: null;
	};

	useEffect(() => {
		setImgWidth(imageWidth);
		setXDelta((-imagesListLength * imageWidth) / 2);
	}, [resize]);

	return (
		<div
			ref={carouselContainerRef}
			className={["flex-row", "S4-carousel-container"].join(" ")}>
			<div
				className="flex-row"
				style={{
					width: "30vw",
					left: "50%",
					transform: "translateX(-50%)",
					position: "absolute",
					bottom: 0,
					justifyContent: "space-evenly",
					zIndex: 3,
				}}>
				<img
					className="S4-nav-arrow"
					onClick={() => throttler(navigateLeft)}
					src={L_NAV}
				/>
				<img
					className="S4-nav-arrow"
					onClick={() => throttler(navigateRight)}
					src={R_NAV}
				/>
			</div>

			<div
				className="S4-imgs-container"
				style={{ transform: `translateX(${xDelta}px)` }}>
				{Object.values(images).map((i, idx) => {
					return (
						<img
							key={`S4_GAL_IMG_${idx}`}
							className="S4-img"
							src={i}
							style={{
								opacity:
									currentImgIdx === idx ||
									currentImgIdx === idx + 1 ||
									currentImgIdx === idx - 1
										? 1
										: 0,
								width: imgWidth,
								height: imgWidth,
								left: `${idx * imgWidth}px`,
								transform: currentImgIdx === idx ? "scale(1.1)" : "scale(.8)",
								zIndex: currentImgIdx === idx ? 1 : null,
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default Carousel;
