import React from "react";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(err, errorInfo) {
		console.log(err, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div
					className={["App-bg", "flex-row"].join(" ")}
					style={{ background: "black", zIndex: "200" }}>
					Something isn't right... Please refresh the page to continue.
				</div>
			);
		}

		return this.props.children;
	}
}
export default ErrorBoundary;
