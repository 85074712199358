import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import SIDEVIEW from "./assets/images/Frame 9.png";
import THREEQUART from "./assets/images/Frame 11.png";
import ENGINE from "./assets/images/ad008890-8628-425b-b9ca-29fdf64a7dd2 3.png";
import WHEEL from "./assets/images/ad008890-8628-425b-b9ca-29fdf64a7dd2 4.png";
import LOGO from "./assets/icons/logo.svg";
import Carousel from "./components/carousel/carousel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { firebaseAddMail } from "./firebase/firebase";
import Loader from "./components/loader/loader";
import ErrorBoundary from "./err/errorBoundary";
const preLoader = document.getElementById("preLoadScreen");
function App() {
	gsap.registerPlugin(ScrollTrigger);
	const loaderRef = useRef();
	const initTimeline = useRef();
	const containerRef = useRef();
	const scrollTransition_1 = useRef();
	const scrollTransition_2 = useRef();
	const scrollTransition_3 = useRef();
	const scrollTransition_4 = useRef();
	const scrollTransition_5 = useRef();
	const scrollTransition_6 = useRef();
	const scrollTransition_7 = useRef();
	const sectionRefs = useRef([]);
	const q = gsap.utils.selector(containerRef);

	const [isInitLoading, setIsInitLoading] = useState(true);
	const [S1ImgsLoadProgress, setS1ImgsLoadProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [windowResize, setWindowResize] = useState(false);

	const nav = ["HOME", "SPECS", LOGO, "GALLERY", "CONTACT"];
	const specs = {
		engine: {
			engine: "V8",
			valvetrain: "DOHC 2 Valves / Cyl",
			displacement: "5341 cc / 325.9 in³",
			bore: "	100 mm / 3.94 in",
			stroke: "85 mm / 3.35 in",
		},
		performance: {
			power: "298.3 kw / 400.0 bhp @ 6000 rpm",

			steering: "Rack & Pinion",
			transmission: "	5-Speed Manual",
			"top speed": "273.6 kph / 170 mph",
			"0 – 60 mph": "	4.9 seconds",
			"break horsepower": "432 bhp",
		},
		dimensions: {
			wheelbase: "2610 mm / 102.8 in",
			length: "	4674 mm / 184.0 in",
			width: "1880 mm / 74.0 in",
			height: "	1341 mm / 52.8 in",
		},
	};
	const initConfig = [
		{
			class: [
				".S1-main-text",
				".S2-main-text",
				".S4-main-text",
				".S6-main-text",
			],
			styles: { y: 300, autoAlpha: 0 },
		},
		{ class: ".S1-img", styles: { autoAlpha: 0, y: -50 } },
		{
			class: [".S1-img", ".S4-imgs-container", ".scroll-more-svg"],
			styles: { autoAlpha: 0 },
		},
		{ class: ".S2-img", styles: { autoAlpha: 0, x: -100 } },
		{ class: ".S2-details-text", styles: { y: 300, autoAlpha: 0 } },
		{ class: [".S3-quad"], styles: { scaleX: 0, autoAlpha: 0 } },
		{
			class: [".S3-details-text"],
			styles: { y: 100, autoAlpha: 0 },
		},
	];
	const handleRouting = (idx) => {
		const docHeight = window.innerHeight * 4;
		const routeMap = {
			0: 0,
			1: docHeight * 0.25,
			2: docHeight * 0.625,
			3: docHeight,
		};
		return window.scrollTo({
			top: routeMap[idx],
			left: 0,
			behavior: "smooth",
		});
	};
	const schema = Yup.object({
		name: Yup.string().trim("Required").required("Required"),
		email: Yup.string()
			.required("Required")
			.email("Please enter a valid email"),
		message: Yup.string().trim("Required").required("Required"),
	});
	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			message: "",
		},
		validationSchema: schema,
		onSubmit: async (values) => {
			setIsLoading(true);
			try {
				await firebaseAddMail(values);
				formik.resetForm();
				setIsLoading(false);
				setIsSuccess(true);
				setTimeout(() => {
					setIsSuccess(false);
				}, 3000);
			} catch (err) {
				throw new Error(err);
			}
		},
	});

	const initAnim = () => {
		const mainText_S1 = gsap.utils.toArray(q(".S1-main-text"));
		initTimeline.current = gsap
			.timeline({ defaults: { transition: "1s ease" } })
			.to(loaderRef.current, {
				borderRadius: "100%",
				yPercent: "-100",
				duration: 1.5,
			})

			.to(q(".S1-img"), {
				autoAlpha: 1,
				y: 0,
			})

			.to(mainText_S1, {
				y: 0,
				stagger: 0.1,
				autoAlpha: 1,
				transition: "1s ease",
				delay: -1,
			})
			.to(q(".scroll-more-svg"), { autoAlpha: 1 })
			.to(q(".bg-text"), { autoAlpha: 0.1 })
			.add(() => setIsInitLoading(false))
			.timeScale(2.5);
	};
	const registerResize = () => {
		setWindowResize((p) => !p);
	};
	useEffect(() => {
		initConfig.forEach((i) => {
			gsap.set(q(i.class), i.styles);
		});

		window.addEventListener("resize", registerResize);
		return () => {
			window.removeEventListener("resize", registerResize);
		};
	}, []);
	useEffect(() => {
		if (S1ImgsLoadProgress === 2) {
			setTimeout(() => {
				preLoader.remove();
				initAnim();
			}, 1000);
		}
	}, [S1ImgsLoadProgress]);
	useEffect(() => {
		const mainText_S2 = gsap.utils.toArray(q(".S2-main-text"));
		const scrollSharedConfig = {
			trigger: q(".S1-wrapper"),
			scrub: 1,
			invalidateOnRefresh: true,
			fastScrollEnd: 1000,
		};
		ScrollTrigger.create({
			trigger: q(".S1-wrapper"),
			start: "top top",
			end: "+=150%",
			pin: ".S1",
		});

		scrollTransition_1.current = gsap
			.timeline({
				scrollTrigger: {
					...scrollSharedConfig,
					start: "top top",
					end: "5% top",
				},
			})
			.to(q(".S1-img"), {
				autoAlpha: 0,
				y: -50,
				transition: "1s ease",
				delay: -0.5,
			})
			.to(q([".S1-main-text", ".S1-accent-text-container"]), {
				xPercent: -500,
				autoAlpha: 1,
				delay: -0.5,
				stagger: 0.2,
			});
		scrollTransition_2.current = gsap
			.timeline({
				scrollTrigger: {
					...scrollSharedConfig,
					start: "10% top",
					end: "25% top",
				},
			})
			.to(
				q(".S2-img"),
				{
					autoAlpha: 1,
					x: 0,
					transition: "1s ease",
				},
				0
			)
			.to(
				q(".S2-details-text"),
				{ y: 0, transition: "1s ease", autoAlpha: 1 },
				0
			)
			.to(
				mainText_S2,
				{
					autoAlpha: 1,
					y: 0,
					stagger: 0.1,
				},
				0
			);
		scrollTransition_3.current = gsap
			.timeline({
				scrollTrigger: {
					...scrollSharedConfig,
					start: "30% top",
					end: "40% top",
				},
			})
			.to(
				q([".S2-details-text", ".S2-main-text"]),
				{ xPercent: 100, transition: "1s ease", autoAlpha: 0 },
				0
			)
			.to(
				q(".S2-img"),
				{
					xPercent: -100,
					scale: 1,
					transition: "1s ease",
				},
				0
			)
			.to(".S3-quad", {
				scaleX: 1,
				autoAlpha: 1,
				stagger: 0.3,
			})
			.to(".scroll-more-svg", { autoAlpha: 0 }, 0);
		scrollTransition_5.current = gsap
			.timeline({
				scrollTrigger: {
					trigger: q(".S4"),
					start: "-50% top",
					end: "-70% top",
					scrub: 1,
				},
			})
			.to(q(".S4-main-text"), {
				y: 0,
				autoAlpha: 1,
				transition: "1s ease",
			})
			.to(q(".S4-imgs-container"), { autoAlpha: 1, transition: "1s ease" });
		scrollTransition_6.current = gsap
			.timeline({
				scrollTrigger: {
					trigger: q(".S4"),
					start: "90% top",
					end: "bottom top",
					scrub: 1,
				},
			})
			.to(q(".S4-main-text"), {
				y: 300,
				transition: "1s ease",
			})
			.to(q(".S4-imgs-container"), { autoAlpha: 0, transition: "1s ease" })
			.to(q([".S5-main-text", ".S5-details-text"]), {
				y: 0,
				autoAlpha: 1,
				transition: "1s ease",
			});
		scrollTransition_7.current = gsap
			.timeline({
				scrollTrigger: {
					trigger: q(".S4"),
					start: "20% top",
					end: "center top",
					scrub: 1,
				},
			})
			.to(q(".S6-main-text"), {
				y: 0,
				autoAlpha: 1,
				transition: "1s ease",
			});
		return () => ScrollTrigger.getAll().forEach((i) => i.kill());
	}, []);
	const moodyFrame = [
		{ deg: 90, width: "30vw", height: "100vh", left: 0 },
		{ deg: -90, width: "20vw", height: "100vh", right: 0 },
		{ deg: 180, width: "100vw", height: "30vh", top: 0 },
		{ deg: 0, width: "100vw", height: "40vh", bottom: 0 },
	];

	return (
		<>
			<ErrorBoundary>
				<main ref={containerRef}>
					<nav className={["flex-col", "main-nav"].join(" ")}>
						<div className={["flex-row", "nav-inner-container"].join(" ")}>
							{nav.map((i, idx) => {
								return idx == 2 ? (
									<img src={i} style={{ width: "150px" }} />
								) : (
									<h5
										onClick={() => handleRouting(idx > 2 ? idx - 1 : idx)}
										key={`NAV_TEXT_${idx}`}
										className="nav-link"
										style={{ fontWeight: "lighter" }}>
										{i}
									</h5>
								);
							})}
						</div>
					</nav>
					<svg className={["scroll-more-svg", "flex-col"].join(" ")}>
						<line
							y1={window.innerHeight * 0.2}
							y2={window.innerHeight * 0.6}
							x1="80"
							x2="80"
							stroke="white"
							opacity=".5"
						/>
						<text
							x="25%"
							y="75%"
							fill="white"
							dominantBaseline="hanging"
							opacity=".5">
							1986
						</text>
					</svg>
					<div className={["App", "App-bg"].join(" ")}></div>
					<section
						className={["full-page", "section", "S1-wrapper"].join(" ")}
						style={{ height: "250vh" }}>
						<section
							className={["full-page", "section", "flex-col", "S1"].join(" ")}>
							{moodyFrame.map((i, idx) => {
								return (
									<div
										style={{
											background: `linear-gradient(${i.deg}deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)`,
											position: "fixed",
											top: i.top && i.top,
											left: i.left && i.left,
											bottom: i.bottom && i.bottom,
											right: i.right && i.right,
											width: i.width,
											height: i.height,
											zIndex: 2,
										}}
									/>
								);
							})}
							<img
								onLoad={() => setS1ImgsLoadProgress((p) => (p += 1))}
								className="S1-img"
								src={SIDEVIEW}
							/>
							<div
								className={[
									"flex-row",
									"main-text-container",
									"S1-main-text-container",
								].join(" ")}>
								<span className="S1-main-text">THE</span>
								<span className="S1-main-text">V8</span>
								<span className="S1-main-text">VANTAGE</span>
								<span className="S1-main-text">X-PACK</span>
							</div>
							<div
								className={["flex-row", "S1-accent-text-container"].join(" ")}>
								<p
									className="flex-col"
									style={{
										height: "16vh",
										justifyContent: "flex-start",
										alignItems: "flex-start",
										fontSize: "14px",
									}}>
									<span style={{ margin: "5px 0 " }}>MANUAL RHD</span>
									<span style={{ margin: "5px 0 " }}>MILEAGE: 68,000</span>
									<span style={{ margin: "5px 0 " }}>COLOUR: YORK RED</span>
								</p>
								<p
									style={{
										height: "16vh",
										width: "50%",
										fontSize: "14px",
									}}>
									Only 20 Examples of the V8 Vantage X pack were produced in
									right hand drive manual form and this has to be the best
									remaining example available having covered only 65,000 miles
									from new in 1986.
								</p>
							</div>
							<img
								onLoad={() => setS1ImgsLoadProgress((p) => (p += 1))}
								className="S2-img"
								src={THREEQUART}
							/>{" "}
							<div
								className={["flex-col", "S2-details-text-container"].join(" ")}>
								<p className="S2-details-text">
									This website is for a private sale of this iconic James Bond
									Aston Martin. The exhilarating elegance of driving the V8
									Vantage X-pack on the greatest roads in Europe is an
									incomparable experience. The sense of romance, panache, style,
									excitement, and speed is vividly shared via the admiration and
									goodwill of passing motorists and onlookers.
									<br /> <br />
									The car was bought in York Red and is in perfect condition,
									owned by me, (Michael) since 1986.
									<br /> <br />
									Light Grey interior hides were specified along with grey
									carpets all of which are in excellent condition. A highly
									figured mirror finish burr walnut dash completes the ensemble.
									<br /> <br />
									The service history on this car is comprehensive detailing the
									works done to the car mostly by Aston Services Dorset. Under
									the bonnet the car is equally good with a well detailed engine
									bay. The big V8 engine starts instantly & is in fine fettle
									given the money and care over 37 years servicing it. The
									engine has also had a works prepared unleaded fuel conversion.
									Lattice alloy wheels with four excellent tyres. <br /> <br />
									Accepting offers over £500k.
								</p>
							</div>
							<div
								className={[
									"flex-row",
									"main-text-container",
									"S2-main-text-container",
								].join(" ")}>
								<span className="S2-main-text">A</span>
								<span className="S2-main-text">CAR</span>
								<span className="S2-main-text">WITH</span>
								<span className="S2-main-text">A</span>
								<span className="S2-main-text">UNIQUE</span>
								<span className="S2-main-text">STORY</span>
							</div>
							<div
								ref={(el) =>
									!sectionRefs.current.includes(el) &&
									sectionRefs.current.push(el)
								}>
								{["quad-black", "quad-01", "quad-02", "quad-black"].map(
									(i, idx) => {
										return (
											<div
												className={[i, "S3-quad", "flex-col"].join(" ")}
												style={{
													position: "absolute",
													left: idx < 2 ? 0 : null,
													right: idx > 1 ? 0 : null,
													bottom: idx % 2 ? 0 : window.innerHeight / 2,
													width: window.innerWidth / 2,
													height: window.innerHeight / 2,
												}}>
												{idx == 0
													? Object.entries(specs.engine).map(
															([key, val], jdx) => {
																return (
																	<div
																		className="flex-row"
																		style={{
																			justifyContent: "space-between",
																			width: "90%",
																		}}>
																		<span className="S3-text">{key}</span>
																		<span className="S3-text">{val}</span>
																	</div>
																);
															}
													  )
													: idx == 3
													? Object.entries(specs.performance).map(
															([key, val], idx) => {
																return (
																	<div
																		className="flex-row"
																		style={{
																			justifyContent: "space-between",
																			width: "90%",
																		}}>
																		<span className="S3-text">{key}</span>
																		<span className="S3-text">{val}</span>
																	</div>
																);
															}
													  )
													: null}
											</div>
										);
									}
								)}{" "}
							</div>
						</section>
					</section>

					<section
						className={["full-page", "section", "flex-row", "S4"].join(" ")}
						style={{ background: "black", position: "relative" }}>
						<div
							className={[
								"flex-row",
								"main-text-container",
								"S4-main-text-container",
							].join(" ")}>
							<span className="S4-main-text">GALLERY </span>
						</div>
						{moodyFrame.map((i, idx) => {
							return (
								<div
									style={{
										background: `linear-gradient(${i.deg}deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)`,
										position: "absolute",
										top: i.top && i.top,
										left: i.left && i.left,
										bottom: i.bottom && i.bottom,
										right: i.right && i.right,
										width: i.width,
										height: i.height,
										zIndex: 2,
									}}
								/>
							);
						})}
						<Carousel resize={windowResize} />
					</section>

					<section
						className={["full-page", "section", "flex-col", "S6"].join(" ")}
						style={{ background: "black" }}>
						<div className={["flex-row", "S6-main-text-container"].join(" ")}>
							<span className="S6-main-text">CONTACT </span>
						</div>
						<form className={["flex-col", "S6-form"].join(" ")}>
							{isLoading ? (
								<img
									className={["logo", "logoAnim"].join(" ")}
									src={LOGO}
									alt="Aston Martin"
								/>
							) : isSuccess ? (
								<small style={{ alignSelf: "center" }}>
									Thank you for your enquiry, Michael will be in touch with you
									soon.
								</small>
							) : (
								<>
									<p style={{ alignSelf: "center" }}>
										For further details or to make an offer or enquiry, please
										use our form below.
									</p>
									{Object.keys(formik.initialValues).map((i, idx) => {
										return (
											<React.Fragment key={`S6_FORM_FRGMNT_${idx}`}>
												<label key={`S6_FORM_LABEL_${idx}`} htmlFor={i}>
													{i.toUpperCase()}
												</label>
												{formik.touched[i] && formik.errors[i] && (
													<small
														key={`S6_FORM_ERROR_${idx}`}
														style={{ marginLeft: "10px" }}>
														{" "}
														*{formik.errors[i]}{" "}
													</small>
												)}
												{i === "message" ? (
													<textarea
														key={`S6_FORM_TXT_AREA${idx}`}
														className={["S6-input", "S6-text-area"].join(" ")}
														{...formik.getFieldProps(i)}
													/>
												) : (
													<input
														key={`S6_FORM_INPUT_${idx}`}
														className="S6-input"
														{...formik.getFieldProps(i)}
													/>
												)}
											</React.Fragment>
										);
									})}
								</>
							)}
							{!isLoading && !isSuccess && (
								<button
									className="S6-button"
									onClick={formik.handleSubmit}
									type="submit">
									SEND
								</button>
							)}
						</form>
					</section>
				</main>
				{isInitLoading && <Loader ref={loaderRef} />}
			</ErrorBoundary>
		</>
	);
}

export default App;
